import React from 'react'
import HeaderItem from './Header/HeaderItem'
import Menu from '../components/Menu';


class Header extends React.Component {
    componentDidMount() {
        window.addEventListener("scroll", () => {
            const topBorder = document
                .getElementById("header")
                .getBoundingClientRect().top;

            if (topBorder >= 0) {
                document.getElementById("ftco-navbar").classList.remove("fixed")
            } else {
                document.getElementById("ftco-navbar").classList.add("fixed")
            }
        })
    }

    componentWillUnmount() {
        window.removeEventListener("scroll");
    }
    
    render() {
        return (
            <>
                <div className="bg-top navbar-light d-flex flex-column-reverse" id="header">
                    <div className="container">
                        <div className="row">
                            <div className="col-auto">
                                <div className="" style={{ backgroundImage: "url('images/logo_09.png')", backgroundRepeat: 'no-repeat', height: '80px', width: '150px', marginLeft: '10px', marginTop: '10px' }} />
                            </div>
                            <div className="row align-items-center">
                                <div className="col-auto mr-auto">
                                    <span className="text-header" style={{ fontSize: '35px' }}>Despachante Glória</span>
                                </div>
                            </div>
                            <HeaderItem title="Telefone" value="(34) 3212-4805" icon="ion-ios-call" />
                            <HeaderItem title="Whatsapp" value="(34) 9 9789-4805" icon="ion-logo-whatsapp" />
                        </div>
                    </div>
                </div>

                <Menu />
            </>
        )
    }
}

export default Header;