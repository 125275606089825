import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { Link } from 'react-scroll'
import Services from '../containers/Services'

const setActiveLink = (id) => {
    let elems = document.querySelectorAll(".nav-item");
    [].forEach.call(elems, function (el) {
        el.classList.remove("active")
    })
    document.getElementById(id).classList.add("active")
    document.getElementById("ftco-nav").classList.remove('show')
}

const cursorStle = {
    cursor: 'pointer'
}

const Menu = () => {
    return (
        <Router>
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark ftco-navbar-light" id="ftco-navbar">
                <div className="container d-flex align-items-center">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav"
                        aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="oi oi-menu"></span> Menu
			            </button>
                    {/* <form action="#" className="searchform order-lg-last" style={{ 'height': '45px' }}>
                        <div className="form-group d-flex">
                            <input type="text" className="form-control pl-3" placeholder="Buscar" />
                            <button type="submit" placeholder="" className="form-control search"><span
                                className="ion-ios-search"></span></button>
                        </div>
                    </form> */}
                    <div className="collapse navbar-collapse" id="ftco-nav">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item active" id="mHome" style={cursorStle}>
                                <Link className="nav-link" onClick={() => setActiveLink('mHome')} to="home" spy={true} smooth={true}>Home</Link>
                            </li>
                            <li className="nav-item" id="mAbout" style={cursorStle}>
                                <Link className="nav-link" onClick={() => setActiveLink('mAbout')} to="about" spy={true} smooth={true}>Quem Somos</Link>
                            </li>
                            <li className="nav-item" id="mService" style={cursorStle}>
                                <Link className="nav-link" onClick={() => setActiveLink('mService')} to="services" spy={true} smooth={true}>Serviços</Link>
                            </li>
                            <li className="nav-item" id="mFreeConsulting" style={cursorStle}>
                                <Link className="nav-link" onClick={() => setActiveLink('mFreeConsulting')} to="freeConsulting" spy={true} smooth={true}>Pesquisar Veículo</Link>
                            </li>
                            <li className="nav-item" id="mCustomers" style={cursorStle}>
                                <Link className="nav-link" onClick={() => setActiveLink('mCustomers')} to="customers" spy={true} smooth={true}>Clientes</Link>
                            </li>
                            <li className="nav-item" id="mContact" style={cursorStle}>
                                <Link className="nav-link" onClick={() => setActiveLink('mContact')} to="contact" spy={true} smooth={true}>Contato</Link>
                            </li>
                            <li className="nav-item" id="mBlog" style={cursorStle}>
                                <Link className="nav-link" onClick={() => setActiveLink('mBlog')} to="blog" spy={true} smooth={true}>Blog</Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
            <Switch>
                <Route exact path="/services">
                    <Services />
                </Route>
            </Switch>
        </Router>
    )
}


export default Menu;