import HorinzontalBar from './HorinzontalBar'
import styled from "styled-components"

const DivStyled = styled.div`
	text-align: center;
  `;

const ULStyled = styled.ul` 
	display: inline-block; 
	text-align: left; 
  `;


const Services2 = () => {
    return (
        <div id="services">
            <HorinzontalBar title="Nossos Serviços" />
            <section className="ftco-section" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-transferencia-icon"></span>
                                </div>
                                <div className="text media-body">
                                    <h3>Transferências de Propriedades</h3>
                                    <p>VERIFICAR DOCUMENTAÇÃO NECESSÁRIA PARA CADA ESTADO.</p>
                                    <p><span style={{ fontWeight: "bold" }}>DOCUMENTAÇÃO NECESSÁRIA MG:</span></p>
                                    <DivStyled>
                                        <ULStyled>
                                            <li>Cópia de Documentos Pessoais</li>
                                            <li>CRV assinado e reconhecido firma</li>
                                            <li>Vistoria do veículo no DETRAN</li>
                                        </ULStyled>
                                    </DivStyled>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-cadasdtramento-crv-icon"></span>
                                </div>
                                <div className="text media-body">
                                    <h3>Cadastramento de CRV</h3>
                                    <p>VERIFICAR DOCUMENTAÇÃO NECESSÁRIA PARA CADA ESTADO</p>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>DOCUMENTAÇÃO NECESSÁRIA MG:</span></p>
                                    <DivStyled>
                                        <ULStyled>
                                            <li>Cópia do CRV assinado e reconhecido firma, frente e verso</li>
                                        </ULStyled>
                                    </DivStyled>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-alteracao-icon"></span>
                                </div>
                                <div className="text media-body">
                                    <h3>Alteração de Dados Inclusão ou Retirada de GRAVAME</h3>
                                    <p>VERIFICAR DOCUMENTAÇÃO NECESSÁRIA PARA CADA ALTERAÇÃO DE DADOS</p>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}>DOCUMENTAÇÃO NECESSÁRIA MG:</span></p>
                                    <DivStyled>
                                        <ULStyled>
                                            <li>Cópia de Documentos Pessoais</li>
                                            <li>CRV orginal em branco</li>
                                            <li>Vistoria do veículos no DETRAN</li>
                                        </ULStyled>
                                    </DivStyled>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-cadasdtramento-crlv-icon"></span></div>
                                <div className="text media-body">
                                    <h3>Emissão do CRLV – Licenciamento Anual</h3>
                                    <p>VERIFICAR DOCUMENTAÇÃO NECESSÁRIA PARA CADA ESTADO</p>
                                    <DivStyled>
                                        <ULStyled>
                                            <li>Cópia do CRV</li>
                                        </ULStyled>
                                    </DivStyled>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-recurso-multa-icon"></span>
                                </div>
                                <div className="text media-body">
                                    <h3>Recurso de multas e processo de suspensão de CNH e PPD.</h3>
                                    <p>CONSULTA DA CNH OU PPD PARA ORÇAMENTO DO RECURSO</p>
                                    <p><span style={{ fontWeight: "bold" }}>DOCUMENTAÇÃO NECESSÁRIA MG:</span></p>
                                    <DivStyled>
                                        <ULStyled>
                                            <li>Cópia da CNH</li>
                                        </ULStyled>
                                    </DivStyled>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-segunda-via-icon"></span>
                                </div>
                                <div className="text media-body">
                                    <h3>Emissão da Segunda Via do CRLV</h3>
                                    <p>VERIFICAR DOCUMENTAÇÃO NECESSÁRIA PARA CADA ESTADO</p>
                                    <p>

                                    </p>
                                    <DivStyled>
                                        <ULStyled>
                                            <li><span style={{ fontWeight: "bold" }}> MG:</span> Copia do documento pessoal e cópia do CRV</li>
                                            <li><span style={{ fontWeight: "bold" }}> SP:</span> Copia do documento pessoal e cópia do CRLV</li>
                                            <li>
                                                <span style={{ fontWeight: "bold" }}> RJ:</span>
                                                <DivStyled>
                                                    <ULStyled>
                                                        <li>Copia do CRV  ou CRLV</li>
                                                        <li>Copia do RG E CPF - proprietário</li>
                                                        <li>Comprovante de residência RJ - proprietário</li>
                                                        <li>Firma por autenticidade</li>
                                                    </ULStyled>
                                                </DivStyled>
                                            </li>
                                        </ULStyled>
                                    </DivStyled>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-segunda-via-icon"></span>
                                </div>
                                <div className="text media-body">
                                    <h3>Emissão da Segunda Via do CRV</h3>
                                    <p>VERIFICAR DOCUMENTAÇÃO NECESSÁRIA PARA CADA ESTADO E CIDADE;</p>
                                    <p>
                                        <span style={{ fontWeight: "bold" }}> DOCUMENTAÇÃO NECESSÁRIA MG:</span>
                                    </p>
                                    <DivStyled>
                                        <ULStyled>
                                            <li>Copia Documentos Pessoais</li>
                                            <li>Requerimento de segunda via e comunicado de perda, assinado e reconhecido firma</li>
                                            <li>Pesquisa</li>
                                            <li>Vistoria do Veículo no DETRAN</li>
                                        </ULStyled>
                                    </DivStyled>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-pesquisa-icon"></span>
                                </div>
                                <div className="text media-body">
                                    <h3>Pesquisas de Veículos</h3>
                                    <p>Consultar situação de veículos</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex">
                            <div className="services-2 border rounded text-center ftco-animate-x">
                                <div className="icon d-inline-flex p-4">
                                    <span className="flaticon-parcelamento-icon"></span>
                                </div>
                                <div className="text media-body">
                                    <h3>Parcelamento em 12x</h3>
                                    <p>Consulte os débitos on-line e realize o pagamento em até 12 vezes no cartão de crédito.</p>
                                    <DivStyled>
                                        <ULStyled>
                                            <li>Parcelamento de multas e serviços</li>
                                            <li>Licenciamento</li>
                                            <li>IPVA</li>
                                            <li>Seguro</li>
                                        </ULStyled>
                                    </DivStyled>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >

    );
}

export default Services2;