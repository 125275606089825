
function Footer() {
    return (
        <footer className="ftco-footer ftco-bg-dark ftco-section">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-6 col-lg-6">
                        <div className="ftco-footer-widget mb-5">
                            <h2 className="ftco-heading-2">Dúvidas? Entre em contato</h2>
                            <div className="block-23 mb-3">
                                <ul>
                                    <li><span className="icon icon-map-marker"></span><span className="text">Av. Floriano Peixoto, 1832 - Brasil, Uberlândia - MG</span></li>
                                    <li><span className="icon icon-phone"></span><span className="text">(34) 3212-4805</span></li>
                                    <li><span className="icon icon-whatsapp"></span><span className="text"><a href="https://wa.me/5534997894805" target="_blank" rel="noopener noreferrer">(34) 9 9789-4805</a></span></li>
                                    <li><a href="/#"><span className="icon icon-envelope"></span><span
                                        className="text">despachantegloria@hotmail.com</span></a></li>
                                </ul>
                            </div>
                            <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
                                {/* <li className="ftco-animate"><a target="_blank" rel="noopener noreferrer" href="/#"><span className="icon-twitter"></span></a></li> */}
                                <li className="ftco-animate"><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/DespachanteGloria/"><span className="icon-facebook"></span></a></li>
                                <li className="ftco-animate"><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/gloriadespachante/"><span className="icon-instagram"></span></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6 col-lg-6">
                        <div className="ftco-footer-widget mb-5">
                            <h2 className="ftco-heading-2">Novidades</h2>
                            <div className="block-21 mb-4 d-flex">
                                <a href="/#" className="blog-img mr-4" style={{ backgroundImage: "url('images/image_1.jpg')" }}></a>
                                <div className="text">
                                    <h3 className="heading">
                                        <a target="_blank" rel="noopener noreferrer" href="https://www.detran.mg.gov.br/sobre-o-detran-1/sala-de-imprensa/noticias/campanha-educativa-aborda-a-seguranca-no-transito-para-idosos">
                                            Campanha educativa aborda a segurança no trânsito para idosos
                                        </a>
                                    </h3>
                                    <div className="meta">
                                        <div><a href="/#"><span className="icon-calendar"></span> 15 Julho, 2021</a></div>
                                    </div>
                                </div>
                            </div>
                            <div className="block-21 mb-5 d-flex">
                                <a href="/#" className="blog-img mr-4" style={{ backgroundImage: "url('images/image_2.jpg')" }}></a>
                                <div className="text">
                                    <h3 className="heading"><a target="_blank" rel="noopener noreferrer" href="https://www.detran.mg.gov.br/sobre-o-detran-1/sala-de-imprensa/noticias/lei-seca-completa-13-anos">Lei Seca completa 13 anos</a>
                                    </h3>
                                    <div className="meta">
                                        <div><a href="/#"><span className="icon-calendar"></span> 18 Junho, 2021</a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 col-lg-3">
                        <div className="ftco-footer-widget mb-5">
                            <h2 className="ftco-heading-2">Inscreva-se!</h2>
                            <form action="#" className="subscribe-form">
                                <div className="form-group">
                                    <input type="text" className="form-control mb-2 text-center"
                                        placeholder="Digite seu melhor email" />
                                    <input type="submit" value="Inscrever" className="form-control submit px-3" />
                                </div>
                            </form>
                        </div>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-md-12 text-center">
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;