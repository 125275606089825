import HorinzontalBar from './HorinzontalBar';

const Posts = () => {
    return (
        <div id="blog">
            <HorinzontalBar title="Últimas Notícias" />

            <section className="ftco-section" >
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-4 ftco-animate-x">
                            <div className="blog-entry">
                                <a href="blog-single.html" className="block-20 d-flex align-items-end"
                                    style={{ backgroundImage: "url('images/image_1.jpg')" }}>
                                    <div className="meta-date text-center p-2">
                                        <span className="day">22</span>
                                        <span className="mos">Julho</span>
                                        <span className="yr">2021</span>
                                    </div>
                                </a>
                                <div className="text border border-top-0 p-4">
                                    <h3 className="heading">Uberlândia: Vistoria para transferência veicular - agendamento online</h3>
                                    <p>A Polícia Civil de Minas Gerais (PCMG), por meio da 16ª Circunscrição Regional de Trânsito (Ciretran), realizará, no próximo mês, mutirão de vistoria para transferência de veículos na cidade de Uberlândia, no Triângulo Mineiro.</p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0"><a target="_blank" rel="noopener noreferrer" href="https://www.detran.mg.gov.br/sobre-o-detran-1/sala-de-imprensa/noticias/uberlandia-vistoria-para-transferencia-veicular-agendamento-online" className="btn btn-primary">Leia Mais <span
                                            className="ion-ios-arrow-round-forward"></span></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate-x">
                            <div className="blog-entry">
                                <a href="blog-single.html" className="block-20 d-flex align-items-end"
                                    style={{ backgroundImage: "url('images/image2_01.jpeg')" }}>
                                    <div className="meta-date text-center p-2">
                                        <span className="day">29</span>
                                        <span className="mos">Julho</span>
                                        <span className="yr">2021</span>
                                    </div>
                                </a>
                                <div className="text border border-top-0 p-4">
                                    <h3 className="heading">Site do Detran-MG permite acesso com cadastro do portal do governo federal</h3>
                                    <p>Com o objetivo de facilitar o acesso aos serviços no site do Departamento de Trânsito de Minas Gerais (Detran-MG),
                                    a Polícia Civil de Minas Gerais (PCMG) disponibilizou uma nova opção de autenticação...</p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0"><a target="_blank" rel="noopener noreferrer" href="https://www.detran.mg.gov.br/sobre-o-detran-1/sala-de-imprensa/noticias/site-do-detran-mg-permite-acesso-com-cadastro-do-portal-do-governo-federal" className="btn btn-primary">Leia Mais <span
                                            className="ion-ios-arrow-round-forward"></span></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4 ftco-animate-x">
                            <div className="blog-entry">
                                <a href="blog-single.html" className="block-20 d-flex align-items-end"
                                    style={{ backgroundImage: "url('images/image3_01.jpg')" }}>
                                    <div className="meta-date text-center p-2">
                                        <span className="day">30</span>
                                        <span className="mos">Junho</span>
                                        <span className="yr">2021</span>
                                    </div>
                                </a>
                                <div className="text border border-top-0 p-4">
                                    <h3 className="heading">Novo Sistema disponibiliza agendamento para serviços de veículos em BH</h3>
                                    <p>A partir desta quinta-feira (1/7), a Polícia Civil de Minas Gerais (PCMG) disponibiliza um novo sistema de agendamento para
                                    os serviços realizados na Divisão de Registro de Veículos (DRV) do Departamento de Trânsito de Minas Gerais...</p>
                                    <div className="d-flex align-items-center mt-4">
                                        <p className="mb-0"><a target="_blank" rel="noopener noreferrer" href="https://www.detran.mg.gov.br/sobre-o-detran-1/sala-de-imprensa/noticias/novo-sistema-disponibiliza-agendamento-para-servicos-de-veiculos-em-bhh" className="btn btn-primary">Leia Mais <span
                                            className="ion-ios-arrow-round-forward"></span></a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Posts;