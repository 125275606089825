import React from 'react';

import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import Carousel from './components/Carousel';
import FreeConsulting from './components/FreeConsulting';
import HorinzontalBar from './components/HorinzontalBar';
import About from './components/About';
import Services2 from './components/Services2';
import Testimonials from './components/Testimonials';
import Posts from './components/Posts';
import Contact from './components/Contact/Contact';


class App extends React.Component {
  render() {
    return (
      <div className="App">
        <Header />

        <Carousel />

        <FreeConsulting />

        <About />

        <Services2 />

        <Contact />

        <Posts />
        
        {/* <Testimonials /> */}

        <HorinzontalBar />

        <Footer />
      </div>
    );
  }
}


export default App;
