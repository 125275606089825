const axios = require('axios')
const FormData = require('form-data')

const admin = {
    sendEmail(entity) {
        const form = new FormData();
        form.append('name', entity.name);
        form.append('email', entity.email);
        form.append('subject', entity.subject);
        form.append('message', entity.message);

        return axios.post('http://despgloria.com.br/index.php/api/sendmail', form, { headers: { "Content-Type": "multipart/form-data" } })
            .then(response => response)
            .catch(function (error) {
                console.log(error)
            })
    }
}

export default admin