function Services() {
    return (
        <section className="ftco-section ftco-no-pt">
            <div className="container">
                <div className="row justify-content-center mb-5">
                    <div className="col-md-8 text-center heading-section ftco-animate-x">
                        <span className="subheading">Services</span>
                        <h2 className="mb-4">Why Choose Us?</h2>
                        <p>Separated they live in. A small river named Duden flows by their place and supplies it with the
						necessary regelialia. It is a paradisematic country</p>
                    </div>
                </div>
                <div className="row tabulation mt-4 ftco-animate-x">
                    <div className="col-md-4">
                        <ul className="nav nav-pills nav-fill d-md-flex d-block flex-column">
                            <li className="nav-item text-left">
                                <a className="nav-link active py-4" data-toggle="tab" href="#services-1"><span
                                    className="flaticon-analysis mr-2"></span> Transferências</a>
                            </li>
                            <li className="nav-item text-left">
                                <a className="nav-link py-4" data-toggle="tab" href="#services-2"><span
                                    className="flaticon-business mr-2"></span> Segunda Via CRV-e</a>
                            </li>
                            <li className="nav-item text-left">
                                <a className="nav-link py-4" data-toggle="tab" href="#services-3"><span
                                    className="flaticon-insurance mr-2"></span> Alteração de Dados</a>
                            </li>
                            <li className="nav-item text-left">
                                <a className="nav-link py-4" data-toggle="tab" href="#services-4"><span
                                    className="flaticon-money mr-2"></span> Regularização de Suspensão</a>
                            </li>
                            <li className="nav-item text-left">
                                <a className="nav-link py-4" data-toggle="tab" href="#services-5"><span
                                    className="flaticon-rating mr-2"></span> Emissão de CRLV-e</a>
                            </li>
                            <li className="nav-item text-left">
                                <a className="nav-link py-4" data-toggle="tab" href="#services-6"><span
                                    className="flaticon-search-engine mr-2"></span> Comunicação de Venda</a>
                            </li>
                            <li className="nav-item text-left">
                                <a className="nav-link py-4" data-toggle="tab" href="#services-7"><span
                                    className="flaticon-search-engine mr-2"></span> Placas MercoSul</a>
                            </li>
                            <li className="nav-item text-left">
                                <a className="nav-link py-4" data-toggle="tab" href="#services-8"><span
                                    className="flaticon-search-engine mr-2"></span> Pesquisa de Veículos</a>
                            </li>
                            <li className="nav-item text-left">
                                <a className="nav-link py-4" data-toggle="tab" href="#services-9"><span
                                    className="flaticon-search-engine mr-2"></span> Parcelamento em 12x</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-8">
                        <div className="tab-content">
                            <div className="tab-pane container p-0 active" id="services-1">
                                <div className="img" style={{ backgroundImage: "url('images/project-2.jpg')" }}></div>
                                <h3><a href="/#">Business Analysis</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>
                            <div className="tab-pane container p-0 fade" id="services-2">
                                <div className="img" style={{ backgroundImage: "url('images/project-3.jpg')" }}></div>
                                <h3><a href="/#">Business Consulting</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>
                            <div className="tab-pane container p-0 fade" id="services-3">
                                <div className="img" style={{ backgroundImage: "url('images/project-4.jpg')" }}></div>
                                <h3><a href="/#">Business Insurance</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>
                            <div className="tab-pane container p-0 fade" id="services-4">
                                <div className="img" style={{ backgroundImage: "url('images/project-5.jpg')" }}></div>
                                <h3><a href="/#">Global Investigation</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>
                            <div className="tab-pane container p-0 fade" id="services-5">
                                <div className="img" style={{ backgroundImage: "url('images/project-6.jpg')" }}></div>
                                <h3><a href="/#">Audit &amp; Evaluation</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>
                            <div className="tab-pane container p-0 fade" id="services-6">
                                <div className="img" style={{ backgroundImage: "url('images/project-1.jpg')" }}></div>
                                <h3><a href="/#">Marketing Strategy</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>

                            <div className="tab-pane container p-0 fade" id="services-7">
                                <div className="img" style={{ backgroundImage: "url('images/project-1.jpg')" }}></div>
                                <h3><a href="/#">Marketing Strategy</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>

                            <div className="tab-pane container p-0 fade" id="services-8">
                                <div className="img" style={{ backgroundImage: "url('images/project-1.jpg')" }}></div>
                                <h3><a href="/#">Marketing Strategy</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>

                            <div className="tab-pane container p-0 fade" id="services-9">
                                <div className="img" style={{ backgroundImage: "url('images/project-1.jpg')" }}></div>
                                <h3><a href="/#">Marketing Strategy</a></h3>
                                <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia,
                                there live the blind texts. Separated they live in Bookmarksgrove right at the coast of
								the Semantics, a large language ocean.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Services;