import Banner from './Banner'
import ContactBoxes from './ContactBoxes'


function Contact() {
    return (
        <div id="contact">
            <Banner />
            <ContactBoxes />
        </div>
    );
}

export default Contact;