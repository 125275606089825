const HeaderItem = (props) => {
    return (
        <div className="col-auto">
            <div className="col-md d-flex topper align-items-center align-items-stretch py-md-4">
                <div className="icon d-flex justify-content-center align-items-center"><span
                    className={props.icon}></span></div>
                <div className="text">
                    <span>{props.title}</span>
                    <span>{props.value}</span>
                </div>
            </div>
        </div>
    )
}

export default HeaderItem