import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Menu from '../../components/Menu';
import Service from '../../components/Services';

const Services = () => {
    return (
        <div>
            <Header />

            <Menu />

            <Service/>

            <Footer/>
        </div>
    )
}


export default Services