import HorinzontalBar from './HorinzontalBar';
const cssText = {
    'fontSize': '18px'
}

const About = () => {
    return (
        <div id="about">
            <HorinzontalBar title="Quem Somos" />
            <section className="ftco-section ftco-about ftco-no-pt ftco-no-pb ftco-counter" id="section-counter">
                <div className="container consult-wrap">
                    <div className="row d-flex align-items-stretch">
                        <div className="col-md-6 wrap-about align-items-stretch d-flex">
                            <div className="img" style={{ backgroundImage: "url('images/about.jpg')" }}></div>
                        </div>
                        <div className="col-md-6 wrap-about ftco-animate-x-x py-md-5 pl-md-5">
                            <div className="heading-section mb-4">
                                <span className="subheading">Bem-vindo(a) ao Despachante Glória</span>
                                <h2>Somos uma empresa que preza pelos valores.</h2>
                            </div>
                            <p>Acreditamos que a cultura organizacional é o que move nossa empresa. </p>
                            <div className="tabulation-2 mt-4">
                                <ul className="nav nav-pills nav-fill d-md-flex d-block">
                                    <li className="nav-item">
                                        <a className="nav-link active py-2" data-toggle="tab" href="#home1"><span
                                            className="ion-ios-home mr-2"></span> Nossa Missão</a>
                                    </li>
                                    <li className="nav-item px-lg-2">
                                        <a className="nav-link py-2" data-toggle="tab" href="#home2"><span
                                            className="ion-ios-person mr-2"></span> Nossa Visão</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link py-2" data-toggle="tab" href="#home3"><span
                                            className="ion-ios-mail mr-2"></span> Nossos Valores</a>
                                    </li>
                                </ul>
                                <div className="tab-content bg-light rounded mt-2">
                                    <div className="tab-pane container p-0 active" id="home1">
                                        <p style={cssText}>Prestar serviços de despachante de maneira ágil, responsável, com qualidade e segurança, afim de garantir a satisfação dos nosso clientes</p>
                                    </div>
                                    <div className="tab-pane container p-0 fade" id="home2">
                                        <p style={cssText}>Ser o melhor portal de soluções para documentação de veículos do Brasil, para melhor atender nossos clientes, dando toda a comodidade e confiabilidade na prestação dos serviços.</p>
                                    </div>
                                    <div className="tab-pane container p-0 fade" id="home3">
                                        <p style={cssText}>Ser integro no atendimento ao cliente, gerando segurança em todos os nossos relacionamentos com os clientes.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-5">
                                <div className="col-md d-flex justify-content-center counter-wrap ftco-animate-x">
                                    <div className="block-18">
                                        <div className="icon"><span className="flaticon-doctor"></span></div>
                                        <div className="text">
                                            <strong className="number" data-number="26110">0</strong>
                                            <span>Clientes Felizes</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md d-flex justify-content-center counter-wrap ftco-animate-x">
                                    <div className="block-18">
                                        <div className="icon"><span className="flaticon-doctor"></span></div>
                                        <div className="text">
                                            <strong className="number" data-number="15101">0</strong>
                                            <span>CRLVs emitidas</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md d-flex justify-content-center counter-wrap ftco-animate-x">
                                    <div className="block-18">
                                        <div className="icon"><span className="flaticon-doctor"></span></div>
                                        <div className="text">
                                            <strong className="number" data-number="31688">0</strong>
                                            <span>Serviços Realizados</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default About;