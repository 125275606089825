const goToServices = () => window.location.hash = 'services'

const Carousel = () => {
    return (
        <div id="home">
            <section className="home-slider owl-carousel">
                <div className="slider-item" style={{ backgroundImage: "url('images/emplacamento.jpg')" }}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-start"
                            data-scrollax-parent="true">
                            <div className="col-md-7 ftco-animate mb-md-5">
                                <span className="subheading">Conheça nossos Serviços</span>
                                <h1 className="mb-4">Placas do MercoSul</h1>
                                <p><a href="#!" onClick={goToServices} className="btn btn-primary px-4 py-3 mt-3">Nossos Serviços</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item" style={{ backgroundImage: "url('images/documento_crv.jpg')" }}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-start"
                            data-scrollax-parent="true">
                            <div className="col-md-7 ftco-animate mb-md-5">
                                <span className="subheading">Conheça nossos Serviços</span>
                                <h1 className="mb-4">Segunda Via CRV</h1>
                                <p><a href="#!" onClick={goToServices} className="btn btn-primary px-4 py-3 mt-3">Nossos Serviços</a></p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="slider-item" style={{ backgroundImage: "url('images/documento_crlv_e.jpg')" }}>
                    <div className="overlay"></div>
                    <div className="container">
                        <div className="row no-gutters slider-text align-items-center justify-content-start"
                            data-scrollax-parent="true">
                            <div className="col-md-7 ftco-animate mb-md-5">
                                <span className="subheading">Conheça nossos Serviços</span>
                                <h1 className="mb-4">Emissão de CRLV-e</h1>
                                <p><a href="#!" onClick={goToServices} className="btn btn-primary px-4 py-3 mt-3">Nossos Serviços</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Carousel;