import React, { useState } from "react"
import LoadingOverlayStyled from './LoadingOverlayStyled'
import Modal from './Modal'
import admin from '../resources/admin'



const FreeConsulting = () => {
    const [open, setOpen] = React.useState(false)
    const [title, setTitle] = React.useState(null)
    const [message, setMessage] = React.useState(null)
    const [disableBtn, setDisableBtn] = React.useState(false)
    const [loaded, setLoaded] = React.useState(false)


    const initialFormData = Object.freeze({
        email: "",
        nome: "",
        placa: "",
        renavam: ""
    })

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoaded(true)
        setDisableBtn(true)

        let errors = 0

        for (let field in formData) {
            if (!formData[field]) {
                errors++
            }
        }

        if (errors) {
            setTitle("Validando informações.")
            setMessage("Por favor verifique as informações. Todos os campos são obrigatórios")
            setLoaded(false)
            setOpen(true)
            setDisableBtn(false)
        } else {
            sendEmail({
                name: formData.nome,
                email: formData.email,
                subject: 'CONSULTE SEU VEÍCULO',
                message: `Você recebeu uma solicitação de consulta de veículo.<br/>
                <br/>Placa: ${formData.placa}
                <br/>RENAVAM: ${formData.renavam}`
            })
        }
    }

    const handleClickOpen = (title) => {
        setTitle(title)
        setMessage("Recebemos sua solicitação de consulta. Em breve entraremos em contato.")
        setOpen(true)
        setDisableBtn(false)
    }

    const handleClose = () => {
        setOpen(false)
        updateFormData({
            email: "",
            nome: "",
            placa: "",
            renavam: ""
        })
    }

    const sendEmail = (data) => {
        admin.sendEmail(data)
            .then(res => {
                setLoaded(false)
                handleClickOpen(res.data)
            })
    }

    return (
        <>
            <LoadingOverlayStyled
                loaded={loaded}
                text='Enviando mensagem!'
            >
            </LoadingOverlayStyled>
            <React.StrictMode>
                <Modal
                    title={title}
                    text={message}
                    handleClose={handleClose}
                    open={open}
                />
            </React.StrictMode>
            <section className="ftco-section ftco-no-pt ftco-no-pb ftco-consult" id="freeConsulting">
                <div className="container">
                    <div className="row d-flex no-gutters align-items-stretch	consult-wrap">
                        <div className="col-md-5 wrap-about align-items-stretch d-flex">
                            <div className="ftco-animate bg-primary align-self-stretch px-4 py-5 w-100">
                                <h2 className="heading-white mb-4">CONSULTE SEU VEÍCULO</h2>
                                <form className="appointment-form ftco-animate">
                                    <div className="form-group">
                                        <input type="text" name="nome" id="nome" className="form-control" placeholder="Nome Completo" onChange={handleChange} value={formData.nome}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="email" className="form-control" placeholder="Email" onChange={handleChange} value={formData.email}/>
                                    </div>
                                    <div className="form-group">
                                        <input type="text" name="placa" className="form-control" placeholder="Placa" onChange={handleChange} value={formData.placa}/>
                                    </div>

                                    <div className="form-group">
                                        <input type="text" name="renavam" className="form-control" placeholder="Renavam" onChange={handleChange} value={formData.renavam}/>
                                    </div>

                                    <div className="form-group">
                                        <input onClick={handleSubmit} defaultValue="Pesquisar" className="btn btn-secondary py-3 px-4" disabled={disableBtn} />
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-7 wrap-about ftco-animate align-items-stretch d-flex">
                            <div className="bg-white p-5">
                                <h2 className="mb-4">Consulte e parcele em até 12x</h2>
                                <div className="row">

                                    <div className="col-lg-12 d-flex flex-row">
                                        <div className="services">
                                            <div className="icon d-inline-flex">
                                                <span className="flaticon-licenciamento-icon"></span>
                                            </div>
                                            <div className="text media-body">
                                                <h3>Licenciamento</h3>
                                                <p>Consulte dos seus débitos de forma gratuita e realize o licenciamento on-line do seu veículo</p>
                                            </div>
                                        </div>


                                        <div className="services">
                                            <div className="icon d-inline-flex">
                                                <span className="flaticon-ipva-icon"></span>
                                            </div>
                                            <div className="text media-body">
                                                <h3>IPVA</h3>
                                                <p>Verifique se existe algum débito do seu veículo</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-lg-12 d-flex flex-row">
                                        <div className="services">
                                            <div className="icon d-inline-flex p-3">
                                                <span className="flaticon-recurso-multa-icon"></span>
                                            </div>
                                            <div className="text media-body">
                                                <h3>Recurso de multa</h3>
                                                <p>Se você foi multado, consulte-nos e solicite um orçamento para realizar o recurso da multa.</p>
                                            </div>
                                        </div>
                                        <div className="services">
                                            <div className="icon d-inline-flex p-3">
                                                <span className="flaticon-segunda-via-icon"></span>
                                            </div>
                                            <div className="text media-body">
                                                <h3>Segunda via de CRLV</h3>
                                                <p>Solicite a segunda via do CRLV (Certificado de registro e licenciamento do veículo). Porte obrigatório!</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FreeConsulting;