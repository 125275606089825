function Banner() {
    return (
        <section className="hero-wrap hero-wrap-2" style={{ backgroundImage: "url('images/bg_1.jpg')" }}>
            <div className="overlay"></div>
            <div className="container">
                <div className="row no-gutters slider-text align-items-center justify-content-center">
                    <div className="col-md-9 ftco-animate-x text-center">
                        <h1 className="mb-2 bread">Contato</h1>
                        <p className="breadcrumbs">
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Banner;