function HorinzontalBar(props) {
    return (
        <section className="ftco-intro ftco-no-pb img" style={{ backgroundImage: "url('images/bg_3.jpg')" }}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10 text-center heading-section heading-section-white ftco-animate-x">
                        <h2 className="mb-0">{props.title}</h2>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HorinzontalBar;