import React, { useState } from "react"
import Modal from '../Modal'
// import emailjs from 'emailjs-com'
// const userID = 'user_qOYPKG9nEUccUOL4mOdxe'
// emailjs.init(userID);
import LoadingOverlayStyled from '../LoadingOverlayStyled'
import admin from '../../resources/admin'

const ContactBoxes = () => {
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState(null)
    const [title, setTitle] = React.useState(null)
    const [disableBtn, setDisableBtn] = React.useState(false)
    const [loaded, setLoaded] = React.useState(false)

    const sendEmail = (data) => {
        admin.sendEmail(data)
            .then(res => {
                setLoaded(false)
                handleClickOpen(res.data)
            })
    }

    const initialFormData = Object.freeze({
        email: "",
        name: "",
        subject: "",
        message: ""
    });

    const [formData, updateFormData] = useState(initialFormData);

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoaded(true)
        setDisableBtn(true)

        let errors = 0

        for (let field in formData) {
            if (!formData[field]) {
                errors++
            }
        }

        if (errors) {
            setTitle("Validando informações.")
            setMessage("Por favor verifique as informações. Todos os campos são obrigatórios")
            setLoaded(false)
            setOpen(true)
            setDisableBtn(false)
        } else {
            sendEmail(formData)
        }
    }

    const handleClickOpen = (title) => {
        setTitle(title)
        setMessage("Em breve entraremos em contato.")
        setOpen(true)
        setDisableBtn(false)
    };

    const handleClose = () => {
        setOpen(false);
        updateFormData({
            email: "",
            name: "",
            subject: "",
            message: ""
        })
    };
    return (
        <>
            <LoadingOverlayStyled
                loaded={loaded}
                text='Enviando mensagem!'
            ></LoadingOverlayStyled>
            <React.StrictMode>
                <Modal
                    title={title}
                    text={message}
                    handleClose={handleClose}
                    open={open}
                />
            </React.StrictMode>

            <section className="ftco-section contact-section">
                <div className="container">
                    <div className="row d-flex mb-5 contact-info justify-content-center">
                        <div className="col-md-10">
                            <div className="row mb-5">

                                <div className="col-md-3 text-center d-flex">
                                    <div className="border w-100 p-4">
                                        <div className="icon">
                                            <span className="icon-map-o"></span>
                                        </div>
                                        <p><span>Endereço:</span> Av. Floriano Peixoto, 1832 - Brasil, Uberlândia - MG</p>
                                    </div>
                                </div>
                                <div className="col-md-3 text-center d-flex">
                                    <div className="border w-100 p-4">
                                        <div className="icon">
                                            <span className="icon-whatsapp"></span>
                                        </div>
                                        <p><span>Whatsapp:</span><a href="https://wa.me/5534997894805" target="_blank" rel="noopener noreferrer">(34) 9 9789-4805</a></p>
                                    </div>
                                </div>
                                <div className="col-md-3 text-center d-flex">
                                    <div className="border w-100 p-4">
                                        <div className="icon">
                                            <span className="icon-phone"></span>
                                        </div>
                                        <p><span>Telefone:</span>(34) 3212-4805</p>
                                    </div>
                                </div>
                                <div className="col-md-3 text-center d-flex">
                                    <div className="border w-100 p-4">
                                        <div className="icon">
                                            <span className="icon-envelope-o"></span>
                                        </div>
                                        <p><span>E-mail:</span> <a href="mailto:info@yoursite.com">despachantegloria<br />@hotmail.com</a></p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row block-9 justify-content-center mb-5">
                        <div className="col-md-10 mb-md-5">
                            <h2 className="text-center">Se tiver alguma dúvida não hesite em entrar em contato.</h2>
                            <form className="border p-5 contact-form">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Seu Nome" name="name" onChange={handleChange} value={formData.name} />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Seu Email" name="email" onChange={handleChange} value={formData.email} />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Assunto" name="subject" onChange={handleChange} value={formData.subject} />
                                </div>
                                <div className="form-group">
                                    <textarea name="message" cols="30" rows="7" className="form-control" placeholder="Menssagem" onChange={handleChange} value={formData.message}></textarea>
                                </div>
                                <div className="form-group">
                                    <input onClick={handleSubmit} defaultValue="Enviar" className="btn btn-primary py-3 px-5" disabled={disableBtn} />
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default ContactBoxes;